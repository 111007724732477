export enum ERECIPIENTS {
    SKANSKA = 'SKANSKA',
    MER_CUSTOMER_SUPPORT = 'MER_CUSTOMER_SUPPORT',
    MER_CUSTOMER_SERVICE = 'MER_CUSTOMER_SERVICE',
    MER_SALES = 'MER_SALES',
    CHARGING_PARTNER = 'CHARGING_PARTNER',
    HELSINGBORGSHEM = 'HELSINGBORGSHEM',
}

export const skanskaSubjects = {
    orderChargeKeyLeasingCar: 'Beställ laddbricka till förmånsbil och tjänstebil',
    orderChargeKeyUtilityVehicle: 'Beställ laddbricka till bruksbil ',
    orderChargeKeyPrivateCar: 'Beställ laddbricka till privat bil',
    orderChargeKeySkanskaCar: 'Beställ laddbricka till skanskabil',
    orderSkanskaWallbox: 'Beställ laddbox från Mer',
};
