export const formText = {
    name: 'Namn',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    email: 'E-postadress',
    phoneNumber: 'Telefonnummer',
    addressLine1: 'Gatuadress',
    postalCode: 'Postnummer',
    city: 'Postort',
    personalSecurityNumber: 'Personnummer',
    licencePlate: 'Registreringsnummer',
    required: 'Obligatoriskt',
    submit: 'Skicka',
    submitOrder: 'Skicka beställning',
    submitForm: 'Skicka beställning',
    submitSuccess: 'Din beställning har skickats!',
    submitError: 'Något gick fel, försök igen senare',
    postalCodeFormatError: 'Ange 5 siffror',
    emailFormatError: 'Kontrollera e-postadressen',
    securityNumberFormatError: 'Kontrollera personnumret',
    organisationNumberFormatError: 'Kontrollera organisationsnummer',
    licencePlateFormatError: 'Ange 2 till 7 tecken',
    phoneNumberFormatError: 'Kontrollera telefonnumret',
    reference: 'Referens',
    referencePlaceholder: 'Anställningsnummer eller registreringsnummer',
    installationAddress: 'Installationsadress',
    wallboxId: 'Laddbox-ID',
    car: 'Bruksbil',
    campaign: 'Kampanj',
    flatNumber: 'Lägenhetsnummer',
    flatNumberFormatError: 'Ange 4 siffror',
    addressOfInstallation: 'Installationsadress',
    companyName: 'Företagsnamn',
    organisationNumber: 'Organisationsnummer',
    invoiceAddress: 'Faktureringsadress',
    communicationConsent: 'Marknadsföringsmedgivande',
    consentToGdpr: "Jag har läst och godkänner Mer Sweden AB's personuppgiftspolicy.",
    paymentFormatError: 'Kontrollera bankgiro/plusgiro',
    vatNumberFormatError: 'Kontrollera momsregistreringsnumret',
    ibanFormatError: 'Kontrollera IBAN-numret',
    swiftFormatError: 'Kontrollera SWIFT-numret',
};
