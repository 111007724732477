import { LanguageSelectorContext } from 'contexts/LanguageSelectorContext';
import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';
import GlobalStyle from 'styles/globalStyle';
import PageLayout from 'components/layout/PageLayout';
import PublicRoute from 'components/PublicRoute';

type RouteWrapperProps = {
    showLanguageSelector?: boolean;
};
function RouteWrapper({ showLanguageSelector = true }: RouteWrapperProps): JSX.Element {
    const contextValue = useMemo(
        () => ({
            showLanguageSelector,
        }),
        [showLanguageSelector],
    );

    return (
        <>
            <GlobalStyle />
            <PublicRoute>
                <LanguageSelectorContext.Provider value={contextValue}>
                    <PageLayout>
                        <Outlet />
                    </PageLayout>
                </LanguageSelectorContext.Provider>
            </PublicRoute>
        </>
    );
}

export default RouteWrapper;
