import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import {
    borderColorGrey,
    darkBlue,
    darkerBlue,
    lighterGrey,
    primaryMerBlue,
    shadowBlueSharp,
    spaceXs,
    transitionSnappy,
} from 'styles/variables';
import styled from 'styled-components';

const Label = styled.label`
    position: relative;
    cursor: pointer;
    display: flex;
    gap: ${spaceXs};
    max-width: 100%;
    padding: ${spaceXs} 0;

    input {
        opacity: 0;
        width: 0;
        height: 0;
        -moz-appearance: initial;
        -webkit-appearance: initial;

        &:before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            background-color: ${primaryMerBlue};
            transform: translateY(-50%) scale(0);
            transition: all ${transitionSnappy};
            transform-origin: center center;
            margin: 0 0.25rem;
            position: absolute;
            border-radius: 50%;
            cursor: pointer;
            top: 1.125rem;
            left: 0;
        }

        &:checked {
            opacity: 1;
            &:before {
                transform: translateY(-50%) scale(1);
            }
        }
    }

    &:before {
        content: '';
        box-sizing: border-box;
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid #e5e5e5;
        border-radius: 50%;
        transition: box-shadow ${transitionSnappy};
        flex-grow: 0;
        flex-shrink: 0;
    }

    &.checked {
        &:before {
            border-color: ${primaryMerBlue};
        }
    }

    &:hover:not(.disabled) {
        &:before {
            border-color: ${darkBlue};
        }
        input:checked:before {
            background: ${darkBlue};
        }
    }

    &:focus-within {
        &:before {
            box-shadow: ${shadowBlueSharp};
            border-color: ${primaryMerBlue};
        }
    }

    &:active:not(.disabled) {
        &:before {
            border-color: ${darkerBlue};
        }
        input:checked:before {
            background: ${darkerBlue};
        }
    }

    &.disabled {
        cursor: not-allowed;
        &:before {
            border-color: ${borderColorGrey};
            background-color: ${lighterGrey};
        }
        input:checked:before {
            background: ${borderColorGrey};
        }
    }
`;

interface Props {
    id: string;
    name: string;
    value: string;
    label?: React.ReactNode;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    className?: string;
    disabled?: boolean;
    dataTestId?: string;
}

const RadioButton: React.FC<Props> = forwardRef((props: Props, ref: ForwardedRef<never>) => {
    const { id, name, value, label, onChange, checked = false, className = '', disabled, dataTestId } = props;
    return (
        <Label
            className={`${className} ${disabled ? 'disabled' : ''} ${checked ? 'checked' : ''}`}
            data-testid={`label_${dataTestId}`}
        >
            <input
                data-testid={dataTestId}
                type="radio"
                id={id}
                ref={ref}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            {label}
        </Label>
    );
});

export default RadioButton;
