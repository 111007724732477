export const PERSONAL_SECURITY_NUMBER_REGEXP = /^(\d{6}|\d{8})-?(\d{4})$/;
export const EMAIL_REGEXP = /^[^@ \t\r\n]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
export const POSTAL_CODE_REGEXP = /^\d{5}$/;
export const LICENCE_PLATE_REGEXP = /^[a-zA-ZåäöÅÄÖ\d\s]{2,7}$/;
export const PHONE_NUMBER_REGEXP = /^\d{7,13}$/;
export const FLAT_NUMBER_REGEXP = /^\d{4}$/;
export const VAT_NUMBER_REGEXP = /^SE\d{10}01$/;
export const SWEDISH_IBAN_REGEXP = /^SE\d{22}$/;
export const SWEDISH_SWIFT_REGEXP = /^[A-Z]{4}SE[A-Z0-9]{2}([A-Z0-9]{3})?$/;
export const PAYMENT_DETAILS_REGEXP = /^\d{1,8}-\d{1,4}$/; // Bankgiro and Plusgiro
