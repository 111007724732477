import {
    borderColorBlue,
    borderRadiusM,
    font,
    maxWidthContent,
    screenWidthMini,
    spaceL,
    spaceS,
    spaceXl,
    spaceXxl,
} from 'styles/variables';
import styled from 'styled-components';

type MessageProps = {
    Illustration: React.ReactNode;
    title?: string;
    description?: string;
    tag?: keyof JSX.IntrinsicElements;
    children?: React.ReactNode;
    dataTestId?: string;
};

const MessageContainer = styled.section`
    border: 1px solid ${borderColorBlue};
    border-radius: ${borderRadiusM};
    padding: ${spaceXl} ${spaceXxl} ${spaceXxl} ${spaceXxl};
    max-width: ${maxWidthContent};

    @media screen and (max-width: ${screenWidthMini}) {
        padding: ${spaceL};
    }
`;

const MessageArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 25rem;
    margin: 0 auto;

    @media screen and (max-width: ${screenWidthMini}) {
        max-width: unset;
    }

    h1,
    h2,
    h3 {
        font-size: ${font.size.l};
        margin-bottom: ${spaceS};
        line-height: ${spaceS};
        text-align: center;
        line-height: ${font.lineHeight.l};
        margin-top: ${spaceXl};
    }
`;

const Description = styled.p`
    font-size: ${font.size.m};
    text-align: center;
`;

function MessageBox({
    Illustration,
    title = '',
    description = '',
    tag = 'h1',
    children,
    dataTestId,
}: MessageProps): JSX.Element {
    const Heading = tag;
    return (
        <MessageContainer data-testid={dataTestId ?? null}>
            <MessageArea>
                {Illustration}
                {title && <Heading>{title}</Heading>}
                {description && <Description>{description}</Description>}
                {children}
            </MessageArea>
        </MessageContainer>
    );
}

export default MessageBox;
