import { createContext, useContext } from 'react';

type LanguageSelectorContextType = {
    showLanguageSelector: boolean;
};

export const LanguageSelectorContext = createContext<LanguageSelectorContextType>({
    showLanguageSelector: true,
});

export const useLanguageSelector = (): LanguageSelectorContextType => useContext(LanguageSelectorContext);
