import * as yup from 'yup';
import { ButtonType, EToastType } from 'types';
import { EMAIL_REGEXP, POSTAL_CODE_REGEXP } from './assets/constants';
import { ERECIPIENTS } from './assets/types';
import { ETrackingEvent } from 'types/tracking';
import { FieldFlexContainer, Fieldset } from './assets/elements';
import { PRIVACY_POLICY_URL } from './assets/links';
import { UseFormReturn, useForm } from 'react-hook-form';
import { formText } from './assets/swedishFormsTexts';
import { generateLine } from './assets/utils';
import { spaceL } from 'styles/variables';
import { useToast } from 'contexts/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import CheckBox from 'components/forms/CheckBox';
import Form from 'components/forms/Form';
import Page from 'components/layout/Page';
import RadioButtonGroup from 'components/forms/RadioButtonGroup';
import ReactGA from 'react-ga4';
import TextArea from '../../components/forms/TextArea';
import TextInput from 'components/forms/TextInput';
import styled from 'styled-components';
import useSendEmail from './assets/useSendEmail';

const StyledButton = styled(Button)`
    margin-top: ${spaceL};
`;

type FormType = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    destinationName: string;
    placementOfDestination: string;
    destinationRelation: string;
    address: string;
    postalCode: string;
    city: string;
    destinationInfo?: string;
    consentCommunication?: boolean;
    consentGdpr: boolean;
};

const FormSchema = (): yup.ObjectSchema<FormType> =>
    yup.object().shape({
        firstName: yup.string().required(formText.required),
        lastName: yup.string().required(formText.required),
        email: yup.string().required(formText.required).matches(EMAIL_REGEXP, { message: formText.emailFormatError }),
        phoneNumber: yup.string().required(formText.required),
        destinationName: yup.string().required(formText.required),
        placementOfDestination: yup.string().required(formText.required),
        destinationRelation: yup.string().required(formText.required),
        address: yup.string().required(formText.required),
        postalCode: yup
            .string()
            .required(formText.required)
            .matches(POSTAL_CODE_REGEXP, { message: formText.postalCodeFormatError }),
        city: yup.string().required(formText.required),
        destinationInfo: yup.string(),
        consentCommunication: yup.boolean(),
        consentGdpr: yup.boolean().required(formText.required).oneOf([true], formText.required),
    });

const destinationRelationOptions = [
    {
        id: 'owner',
        label: 'Markägare',
        value: 'markägare',
    },
    {
        id: 'tenant',
        label: 'Hyresgäst',
        value: 'hyresgäst',
    },
    {
        id: 'manager',
        label: 'Förvaltare',
        value: 'förvaltare',
    },
    {
        id: 'tipster',
        label: 'Jag vill rekommendera en destination',
        value: 'Jag vill rekommendera en destination',
    },
];

function ChargingPartner(): JSX.Element {
    const formMethods = useForm<FormType>({
        mode: 'onBlur',
        resolver: yupResolver(FormSchema()),
        shouldUnregister: false,
        defaultValues: {
            consentCommunication: false,
            consentGdpr: false,
        },
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = formMethods;

    const { addToast } = useToast();
    const { sendEmail } = useSendEmail();

    const generateEmailBody = (data: FormType): string => {
        return (
            `${generateLine(formText.firstName, data.firstName)}` +
            `${generateLine(formText.lastName, data.lastName)}` +
            `${generateLine(formText.email, data.email)}` +
            `${generateLine(formText.phoneNumber, data.phoneNumber)}` +
            `${generateLine('Namn på destinationen', data.destinationName)}` +
            `${generateLine('Exakt placering av destinationen', data.placementOfDestination)}` +
            `${generateLine('Relation till destinationen', data.destinationRelation)}` +
            `${generateLine(formText.addressLine1, data.address)}` +
            `${generateLine(formText.postalCode, data.postalCode)}` +
            `${generateLine(formText.city, data.city)}` +
            `${generateLine('Information om destinationen', data.destinationInfo ? data.destinationInfo : '-')}` +
            `${generateLine(formText.communicationConsent, data.consentCommunication ? 'Ja' : 'Nej')}` +
            `${generateLine('', formText.consentToGdpr)}`
        );
    };

    const onSubmit = async (data: FormType): Promise<void> => {
        ReactGA.event(ETrackingEvent.SUBMIT_FORM_CHARGING_PARTNER);
        try {
            await sendEmail.mutateAsync({
                subject: 'Laddplatspartner',
                body: generateEmailBody(data),
                recipient: ERECIPIENTS.CHARGING_PARTNER,
            });
            reset();
            addToast({ message: 'Intresseanmälan skickad', type: EToastType.SUCCESS });
            reset();
        } catch (error) {
            if (error instanceof ApiError) {
                addToast({ message: formText.submitError, type: EToastType.ERROR });
            }
        }
    };

    return (
        <Page
            title="Är du intresserad av att bli vår laddplatspartner eller har tips på var vi kan bygga?"
            description="Fyll då i formuläret nedan."
        >
            <Form onSubmit={handleSubmit(onSubmit)} formMethods={formMethods as unknown as UseFormReturn}>
                <FieldFlexContainer>
                    <TextInput
                        label={formText.firstName}
                        required
                        fieldError={errors.firstName}
                        {...register('firstName')}
                    />
                    <TextInput
                        label={formText.lastName}
                        required
                        fieldError={errors.lastName}
                        {...register('lastName')}
                    />
                </FieldFlexContainer>
                <TextInput label={formText.email} required fieldError={errors.email} {...register('email')} />
                <TextInput
                    label={formText.phoneNumber}
                    required
                    fieldError={errors.phoneNumber}
                    {...register('phoneNumber')}
                />
                <TextInput
                    label="Namn på destinationen"
                    required
                    fieldError={errors.destinationName}
                    {...register('destinationName')}
                />
                <TextInput
                    label="Exakt placering av destinationen"
                    required
                    fieldError={errors.placementOfDestination}
                    {...register('placementOfDestination')}
                />
                <RadioButtonGroup
                    title="Vad är din relation till destinationen?"
                    options={destinationRelationOptions}
                    register={register}
                    name="destinationRelation"
                    selectedValue={watch('destinationRelation')}
                    errors={errors.destinationRelation}
                />
                <TextInput
                    label={formText.addressLine1}
                    required
                    fieldError={errors.address}
                    {...register('address')}
                />
                <FieldFlexContainer>
                    <TextInput
                        label={formText.postalCode}
                        required
                        minLength={5}
                        maxLength={5}
                        fieldError={errors.postalCode}
                        {...register('postalCode')}
                    />
                    <TextInput label={formText.city} required fieldError={errors.city} {...register('city')} />
                </FieldFlexContainer>
                <TextArea
                    label="Information om destinationen"
                    fieldError={errors.destinationInfo}
                    placeholder="Vänligen ange mer relevant information, exempelvis vad för mark det gäller."
                    {...register('destinationInfo')}
                />
                <Fieldset>
                    <CheckBox
                        label="Jag samtycker till att Mer Sweden AB kan använda min registrerade information för att skicka information och erbjudande till mig."
                        checked={watch('consentCommunication')}
                        {...register('consentCommunication')}
                    />
                    <CheckBox
                        label={
                            <>
                                Jag har läst och godkänner Mer Sweden AB&apos;s{' '}
                                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                                    personuppgiftspolicy
                                </a>
                                {/**/}.
                            </>
                        }
                        required
                        checked={watch('consentGdpr')}
                        fieldError={errors.consentGdpr}
                        {...register('consentGdpr')}
                    />
                </Fieldset>
                <StyledButton dataTestId="submit" type={ButtonType.SUBMIT} isLoading={sendEmail.isPending}>
                    {formText.submit}
                </StyledButton>
            </Form>
        </Page>
    );
}

export default ChargingPartner;
