import { FieldError as FieldErrorReactHookForm } from 'react-hook-form';
import { font, red, spaceXs } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledFieldError = styled.div<{ $displayErrorTextOnOneLine?: boolean }>`
    ${({ $displayErrorTextOnOneLine }) => $displayErrorTextOnOneLine && 'white-space: nowrap;'}
    margin-top: ${spaceXs};
    color: ${red};
    font-size: ${font.size.s};
    line-height: ${font.lineHeight.l};
`;

type FieldErrorProps = {
    fieldError: FieldErrorReactHookForm;
    dataTestId?: string;
    displayErrorTextOnOneLine?: boolean;
    className?: string;
};

function FieldError({
    fieldError,
    dataTestId,
    displayErrorTextOnOneLine = false,
    className,
}: FieldErrorProps): JSX.Element {
    const { t } = useTranslation();
    const type = fieldError?.type;
    let message = fieldError?.message;

    if (!message) {
        switch (type) {
            case 'required':
                message = 'form.messages.input.required';
                break;
            case 'match':
            case 'pattern':
                message = 'form.messages.input.invalid';
                break;
            default:
                message = 'form.messages.input.unknown';
                break;
        }
    }

    return (
        <StyledFieldError
            $displayErrorTextOnOneLine={displayErrorTextOnOneLine}
            data-testid={dataTestId && `errorMessage_${dataTestId}`}
            className={className}
        >
            {t(message)}
        </StyledFieldError>
    );
}

export default FieldError;
