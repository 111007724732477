import { ButtonType } from 'types';
import { FieldError, FieldValues, UseFormReturn, useFormContext } from 'react-hook-form';
import {
    backgroundColorDisabled,
    black,
    borderColorDisabled,
    borderColorGrey,
    borderRadiusM,
    darkGrey,
    darkerGrey,
    font,
    lighterGrey,
    mediumGrey,
    primaryMerBlue,
    red,
    screenWidthMini,
    shadowBlueSharp,
    spaceM,
    spaceS,
    spaceXl,
    spaceXs,
    spaceXxs,
    textColorDisabled,
    white,
} from 'styles/variables';
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'assets/icons/cross.svg?react';
import FieldErrorMessage from 'components/forms/FieldError';
import IconButton from 'components/clickables/IconButton';
import React, { ChangeEvent, ForwardedRef, useState } from 'react';
import styled from 'styled-components';
import uuid from 'utils/uuid';

export interface Props {
    id?: string;
    name: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    autoComplete?: string;
    label?: string | React.ReactNode;
    placeholder?: string;
    width?: string;
    height?: string;
    className?: string;
    fieldError?: FieldError | null;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onClearFieldClick?: () => void;
    value?: string;
    maxLength?: number;
    dataTestId?: string;
    hideOptionalText?: boolean;
}

const Container = styled.div<{
    width: string;
    height: string;
    disabled: boolean;
    $error: boolean;
    required: boolean;
    readOnly: boolean;
}>`
    width: inherit;
    min-height: ${({ height }) => height};
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${spaceM};

    label {
        color: ${black};
        font-size: ${font.size.m};
        font-weight: ${font.weight.semiBold};
        line-height: ${font.lineHeight.s};
        margin-bottom: 0.5rem;
        transition: color 0.3s ease-in-out;

        @media screen and (max-width: ${screenWidthMini}) {
            line-height: ${font.lineHeight.l};
        }
    }

    textarea {
        padding: 0.8rem ${spaceXl} ${spaceS} ${spaceS};
        margin-bottom: ${spaceXxs};
        width: 100%;
        resize: vertical;
        height: inherit;
        color: ${black};
        background-color: ${white};
        border-width: 2px;
        border-style: solid;
        border-color: ${({ $error }) => ($error ? red : borderColorGrey)};
        border-radius: ${borderRadiusM};
        font-weight: ${font.weight.regular};
        font-family: ${font.body};
        font-size: ${font.size.m};
        transition: all 0.3s ease-in-out;
        outline: none;
        position: relative;

        &:focus {
            box-shadow: ${shadowBlueSharp};
            border-color: ${primaryMerBlue};
        }

        &:disabled {
            color: ${darkerGrey};
            border-color: ${mediumGrey};
            background-color: ${lighterGrey};
        }

        &:disabled,
        &:read-only {
            color: ${textColorDisabled};
            border-color: ${borderColorDisabled};
            background-color: ${backgroundColorDisabled};
        }
    }

    textarea::placeholder {
        color: ${darkGrey};
    }

    .adornment {
        position: absolute;
        right: ${spaceXs};
        top: ${spaceXs};
        text-align: right;
    }
`;

const InputContainer = styled.div`
    position: relative;
`;

const OptionalText = styled.span`
    margin-left: ${spaceXs};
    color: ${darkGrey};
`;

const handleClearFieldClick = (
    name: string,
    onClearFieldClick?: () => void,
    formContext?: UseFormReturn<FieldValues>,
): void => {
    if (onClearFieldClick) onClearFieldClick();
    if (formContext) {
        formContext.setValue(name, '', { shouldDirty: true });
        formContext.setFocus(name);
    }
};

const TextArea: React.FC<Props> = React.forwardRef(
    (
        {
            id = uuid(),
            name,
            required = false,
            disabled = false,
            readOnly = false,
            autoComplete,
            label,
            placeholder = '',
            width = '100%',
            height = '6rem',
            fieldError,
            className,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            onClearFieldClick,
            value,
            maxLength = 500,
            dataTestId,
            hideOptionalText = false,
        }: Props,
        ref: ForwardedRef<never>,
    ) => {
        const { t } = useTranslation();
        const showOptionalText = label && !required && !readOnly && !hideOptionalText;
        const formContext = useFormContext();

        const fieldHasValue = !!formContext?.watch(name);

        const [isCrossVisible, setIsCrossVisible] = useState<boolean>(false);
        const [isFocused, setIsFocused] = useState<boolean>(false);

        const showClearButton = !disabled && !readOnly && (isCrossVisible || (isFocused && fieldHasValue));

        return (
            <Container
                width={width}
                height={height}
                disabled={disabled}
                required={required}
                readOnly={readOnly}
                $error={!!fieldError}
                className={className}
            >
                {label && (
                    <label htmlFor={id} data-testid={`label_${dataTestId ?? name}`}>
                        {label}
                        {showOptionalText && <OptionalText>{`(${t('form.input.optionalFieldText')})`}</OptionalText>}
                    </label>
                )}

                <InputContainer>
                    <textarea
                        id={id}
                        data-testid={dataTestId ?? name}
                        ref={ref}
                        name={name}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        required={required}
                        disabled={disabled}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            if (onChange) onChange(e);
                            setIsCrossVisible(true);
                        }}
                        onFocus={(e) => {
                            if (onFocus) onFocus(e);
                            setIsCrossVisible(fieldHasValue);
                            setIsFocused(true);
                        }}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={value}
                        autoComplete={autoComplete}
                        aria-required={required ? 'true' : 'false'}
                        aria-invalid={fieldError ? 'true' : 'false'}
                    />
                    {showClearButton ? (
                        <span className="adornment">
                            <IconButton
                                onBlur={() => {
                                    setIsCrossVisible(false);
                                }}
                                data-testid={`clearButton_${dataTestId ?? name}`}
                                type={ButtonType.BUTTON}
                                onClick={() => {
                                    handleClearFieldClick(name, onClearFieldClick, formContext);
                                    setIsCrossVisible(false);
                                    setIsFocused(false);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    ) : null}
                </InputContainer>
                {fieldError ? <FieldErrorMessage fieldError={fieldError} dataTestId={dataTestId ?? name} /> : null}
            </Container>
        );
    },
);
export default React.memo(TextArea);
