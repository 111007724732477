import { ButtonSize, ButtonType, ButtonVariant } from 'types';
import { MerButtonStyle } from 'styles/mixins';
import LoadingSpinner from 'components/indicators/LoadingSpinner';
import styled from 'styled-components';

export const MerButton = styled.button`
    ${MerButtonStyle}
    position:relative;

    &.loading {
        color: transparent !important;
        user-select: none;
        > :not(.loadingSpinner) {
            visibility: hidden;
            opacity: 0;
        }
    }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
    position: absolute;
`;

type Props = {
    children: React.ReactNode;
    disabled?: boolean;
    variant?: ButtonVariant;
    size?: ButtonSize;
    onClick?: () => void;
    type?: ButtonType;
    className?: string;
    dataTestId?: string;
    Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    isLoading?: boolean;
};

export default function Button({
    children,
    disabled = false,
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.DEFAULT,
    type = ButtonType.BUTTON,
    onClick,
    className = '',
    dataTestId,
    Icon,
    isLoading = false,
}: Props): JSX.Element {
    return (
        <MerButton
            data-testid={dataTestId}
            type={type}
            disabled={disabled || isLoading}
            className={`btn--${variant} btn--size-${size} ${isLoading ? 'loading' : ''} ${className}`}
            onClick={onClick}
        >
            {isLoading && <StyledLoadingSpinner className="loadingSpinner" />}
            {!!Icon && <Icon />}
            {children}
        </MerButton>
    );
}
