import { ECountry } from 'types';

export type CountryContent = {
    postalCodeRegExp: RegExp;
    postalCodeLength: number;
    countryCode: ECountry;
};

export const SE_CONTENT: CountryContent = {
    postalCodeRegExp: /^\d{5}$/,
    postalCodeLength: 5,
    countryCode: ECountry.SE,
};

export const DE_CONTENT: CountryContent = {
    postalCodeRegExp: /^\d{5}$/,
    postalCodeLength: 5,
    countryCode: ECountry.DE,
};
