import { EPageErrorType } from 'types';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import { font, maxWidthContent, readingWidth, spaceL, spaceS } from 'styles/variables';
import ApiError from 'classes/ApiError';
import ErrorPage from 'pages/ErrorPage';
import styled from 'styled-components';

const PageContainer = styled.div`
    max-width: ${maxWidthContent};
`;

const Description = styled.section`
    max-width: ${readingWidth};
    margin-top: ${spaceS};
    margin-bottom: ${spaceL};
`;

const Header = styled.h1`
    font-size: ${font.size.xl};
    font-family: ${font.bold};
    line-height: ${font.lineHeight.xl};
`;

type Props = {
    title: string;
    description?: string | JSX.Element;
    children: React.ReactNode;
    isLoading?: boolean;
    errors?: ApiError[];
    isPublicPage?: boolean;
    dataTestId?: string;
};

export default function Page({
    title,
    description,
    children,
    isLoading = false,
    errors,
    isPublicPage = false,
    dataTestId,
}: Props): JSX.Element {
    let pageContent = children;
    if (errors && errors.length > 0) {
        if (errors.find((error) => !error.temporary)) {
            pageContent = <ErrorPage type={EPageErrorType.CRITICAL} />;
        } else {
            pageContent = <ErrorPage type={EPageErrorType.TEMPORARY} refresh />;
        }
    }

    // For public pages we dont want to display more info than needed if there is an error
    let showDescription = !!description;
    if (isPublicPage) {
        showDescription = !isLoading && !errors?.length && !!description;
    }

    const descriptionText = typeof description === 'string' ? <p>{description}</p> : description;

    return (
        <PageContainer data-testid={dataTestId}>
            <Header>{title}</Header>
            {showDescription ? <Description>{descriptionText}</Description> : null}
            {isLoading ? <LoadingIndicator displayBorder={false} /> : pageContent}
        </PageContainer>
    );
}
