import { EMAIL_BFF_PREFIX } from 'constants/general';
import { ERECIPIENTS } from './types';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

type useEmailType = {
    subject: string;
    body: string;
    recipient: ERECIPIENTS;
    contentType?: 'text/plain';
    files?: FileList | null;
};

const useSendEmail = (): { sendEmail: UseMutationResult<FormData, ApiError, useEmailType> } => {
    const url = `${EMAIL_BFF_PREFIX}/v1/emails`;

    const { post } = useApi<FormData, FormData>();
    const queryClient = useQueryClient();

    const sendEmail = useMutation<FormData, ApiError, useEmailType>({
        mutationFn: (emailData: useEmailType) => {
            const formData = new FormData();
            formData.append('subject', emailData.subject);
            formData.append('body', emailData.body);
            formData.append('contentType', 'text/plain');
            formData.append('recipients', emailData.recipient);
            if (emailData.files) {
                const attachments = [];
                for (let i = 0; i < emailData.files?.length; i += 1) {
                    attachments.push(emailData.files[i]);
                }
                attachments.forEach((attachment) => formData.append('attachment', attachment));
            }

            return post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [url] }),
    });

    return { sendEmail };
};

export default useSendEmail;
