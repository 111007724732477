import { FormEventHandler } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { JSX } from 'react/jsx-runtime';

type FormProps = {
    children: React.ReactNode;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    className?: string;
    formMethods: UseFormReturn;
};

function Form({ children, onSubmit, className, formMethods }: FormProps): JSX.Element {
    return (
        <FormProvider {...formMethods}>
            <form onSubmit={onSubmit} noValidate className={className}>
                {children}
            </form>
        </FormProvider>
    );
}

export default Form;
