import { black, darkGrey, font, readingWidth, screenWidthMini, spaceS } from 'styles/variables';
import styled from 'styled-components';

export const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: ${readingWidth};
    margin-bottom: ${spaceS};
`;

export const FieldFlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0 ${spaceS};
    > * {
        flex: 1 0 0px;

        @media screen and (max-width: ${screenWidthMini}) {
            flex: 1 1 auto;
        }
    }
`;

export const ExtraInfo = styled.span`
    display: block;
    color: grey;
    margin-top: 0.5rem;
    font-size: ${font.size.s};
    line-height: ${font.lineHeight.m};
`;

export const OptionalText = styled.span`
    color: ${darkGrey};
    margin-left: 0.5rem;
`;

export const Fieldset = styled.fieldset`
    border: none;
    margin-bottom: ${spaceS};
    min-width: 0;
`;

export const Legend = styled.legend`
    color: ${black};
    font-size: ${font.size.m};
    font-weight: ${font.weight.semiBold};
    line-height: ${font.lineHeight.s};
    margin-bottom: 0.5rem;
`;

export const FieldLabel = styled.label`
    color: ${black};
    font-size: ${font.size.m};
    font-weight: ${font.weight.semiBold};
    line-height: ${font.lineHeight.s};
    margin-bottom: 0.5rem;
`;
