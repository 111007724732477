import { CountryContent, DE_CONTENT, SE_CONTENT } from 'constants/countrySettings';
import { ECountry } from 'types';
import { getCountryFromUrl } from './url';

export const getCountrySpecificContent = (): CountryContent => {
    if (getCountryFromUrl() === ECountry.SE) {
        return SE_CONTENT;
    }
    if (getCountryFromUrl() === ECountry.DE) {
        return DE_CONTENT;
    }
    return SE_CONTENT;
};

export const getCountryCode = (): string => {
    const countryCodeMap: { [key: string]: string } = {
        se: '152',
        de: '203',
        uk: '252',
        no: '102',
        at: '304',
    };

    return countryCodeMap[getCountryFromUrl().toLowerCase()];
};
