export const PRIVACY_POLICY_URL = 'https://se.mer.eco/mer/villkor/personuppgiftspolicy/';
export const TERM_OF_SALES_URL = 'https://se.mer.eco/mer/villkor/forsaljningsvillkor/';
export const TERM_OF_SALES_PARKING_LOT_URL =
    'https://se.mer.eco/wp-content/uploads/sites/7/2023/08/Mer-forsaljningsvillkor-bilplats.pdf';
export const GREEN_TECH_INFO_URL = 'https://www.skatteverket.se/privat/fastigheterochbostad/gronteknik';
export const PROPERTY_DESIGNATION_INFO_URL =
    'https://www.lantmateriet.se/sv/fastighet-och-mark/information-om-fastigheter/Min-fastighet/';
export const INSTALLATION_PRICE_LIST_URL =
    'https://se.mer.eco/mer/villkor/forsaljningsvillkor/prislista-tillkommande-kostnader-vid-standardinstallation/';
export const TERMS_AND_AGREEMENT_URL =
    'https://merecogroup.sharepoint.com/sites/forsaljning/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fforsaljning%2FShared%20Documents%2FF%C3%B6rs%C3%A4ljningsavtal%20och%20villkor%2FB2B%20%26%20B2G%20F%C3%B6retag%2FF%C3%B6rs%C3%A4ljningsavtal%20F%C3%B6retag%20bilagor%20LISEA%2FBilaga%20LISEA%20%2D%20Leverans%20Installation%20Service%20Elhandel%20Mer%2Epdf&parent=%2Fsites%2Fforsaljning%2FShared%20Documents%2FF%C3%B6rs%C3%A4ljningsavtal%20och%20villkor%2FB2B%20%26%20B2G%20F%C3%B6retag%2FF%C3%B6rs%C3%A4ljningsavtal%20F%C3%B6retag%20bilagor%20LISEA';
export const EXAMPLE_IMAGES_KEBA_URL = 'https://service.se.mer.eco/sv/exempelbilder-f%C3%B6r-felanm%C3%A4lan';
export const SKANSKA_PARTNER_URL = 'https://se.mer.eco/partner/skanska';
export const SKANSKA_INTRANET_URL =
    'https://one.skanska.com/sv-se/skanska-och-jag/countries/sweden/personalhandboken-sverige/lon_formaner/formaner/bilar-i-skanska/laddning-av-bilar-i-skanska/laddning-vid-bostad/';
