/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError as FieldErrorReactHookForm, UseFormRegister } from 'react-hook-form';
import { Fieldset, Legend } from 'pages/swedishForms/assets/elements';
import { JSX } from 'react/jsx-runtime';
import { spaceM } from 'styles/variables';
import FieldErrorMessage from 'components/forms/FieldError';
import RadioButton from './RadioButton';
import styled from 'styled-components';

const StyledFieldset = styled(Fieldset)`
    margin-bottom: ${spaceM};
`;

interface RadioButton {
    id: string;
    label: string;
    value: string;
}

type RadioButtonGroupProps = {
    name: string;
    title: string | React.ReactNode;
    options: RadioButton[];
    register: UseFormRegister<any>;
    selectedValue: string;
    errors?: FieldErrorReactHookForm;
    className?: string;
    dataTestId?: string;
};

function RadioButtonGroup({
    options,
    register,
    name,
    selectedValue,
    errors,
    title,
    className,
    dataTestId,
}: RadioButtonGroupProps): JSX.Element {
    return (
        <StyledFieldset className={className}>
            <Legend data-testid={`radioGroupTitle_${dataTestId ?? name}`}>{title}</Legend>
            {options.map((option, index) => (
                <RadioButton
                    key={option.id}
                    id={option.id}
                    value={option.value}
                    label={option.label}
                    {...register(name)}
                    checked={selectedValue === option.value}
                    dataTestId={`${dataTestId ?? name}_${index}`}
                />
            ))}
            {errors ? <FieldErrorMessage fieldError={errors} dataTestId={dataTestId ?? name} /> : null}
        </StyledFieldset>
    );
}

export default RadioButtonGroup;
