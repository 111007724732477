import { ButtonSize, ButtonVariant } from 'types';
import {
    black,
    borderRadiusM,
    borderRadiusS,
    cyanBlue,
    darkBlue,
    darkerBlue,
    defaultButtonHeight,
    font,
    lightBlue,
    mediumBlue,
    primaryMerBlue,
    shadowBlueSharp,
    smallButtonHeight,
    spaceS,
    spaceXs,
    white,
} from './variables';

export const MerButtonStyle = `
    color: ${white};
    background-color: ${primaryMerBlue};
    border: none;
    border-radius: ${borderRadiusM};

    font-family: ${font.semiBold};
    display: flex;
    justify-content: center;
    gap: ${spaceXs};
    align-items: center;

    font-size: ${font.size.m};
    padding: 0 ${spaceS};
    height: ${defaultButtonHeight};
    min-width: 5rem;

    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${darkBlue};
    }

    &:focus {
        background-color: ${darkBlue};
        box-shadow: ${shadowBlueSharp};
    }

    &:active {
        background-color: ${darkerBlue};
        box-shadow: none;
    }

    &:disabled {
        cursor: not-allowed;
        box-shadow: none;
        &:not(.loading) {
        opacity: 0.5;
        }
    }
    
    &.btn--size-${ButtonSize.SMALL} {
        font-size: ${font.size.s};
        padding: 0 ${spaceXs};
        height: ${smallButtonHeight};
        min-width: auto;
        border-radius: ${borderRadiusS};
    }

    &.btn--${ButtonVariant.PRIMARY} {
        color: ${white};
        background: ${primaryMerBlue};

        &:hover:not(:disabled) {
            background: ${darkBlue};
        }

        &:active:not(:disabled) {
            background: ${darkerBlue};
        }

        &.loading svg circle {
            stroke: ${white};
        }
    }

    &.btn--${ButtonVariant.SECONDARY} {
        color: ${black};
        background-color: ${lightBlue};

        &:hover:not(:disabled) {
            background-color: ${mediumBlue};
        }

        &:active:not(:disabled) {
            background-color: ${cyanBlue};

        }
    }
`;
